@import "./global.scss";
@import "~video-react/styles/scss/video-react"; // or import scss

$background-hovered: linear-gradient(
  rgba(223, 227, 232, 0.3),
  rgba(223, 227, 232, 0.3)
);
#reviewcards {
  .Polaris-Card {
    // 768
    @media screen and (max-width:$break-lg) {
      width: 1150px !important;
    }
  }
}
// html {
//   overflow-x: hidden;
// }
*{
    scroll-behavior: smooth;
}
// react hot toast
.go4109123758{
z-index: 999999999999 !important;
}
body {
  overflow-x: hidden;
}
.Polaris-Layout {
  padding: 5px;
}
.Polaris-Frame__Navigation {
  // z-index: 500;
  // my
  // top: 8rem !important;
}

.Polaris-Modal-Dialog__Container {
  z-index: 100000;
}

.Polaris-BulkActions__Group--largeScreen {
  display: flex;
}

.Polaris-Navigation__Item {
  border: none !important;
  font-weight: 500;
}

.Polaris-Navigation__Item--selected {
  font-weight: 600;
}

.Polaris-Header-Title {
  font-size: 2.8rem;
  line-height: 3.2rem;
}

.Polaris-Navigation__Item:hover {
  background: linear-gradient(
    rgba(223, 227, 232, 0.3),
    rgba(223, 227, 232, 0.3)
  ) !important;
}

.Polaris-TopBar__LogoContainer {
  background-color: #1b2b34;
}
#mobileMenu {
  margin: 20px 20px 0 20px;
display: none;
  cursor: pointer;
  // width: 100%;
  justify-content: space-between;
  align-items: center;
  h5{
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D7175;
  }
}
@media all and (max-width: 768px){
#mobileMenu{
  display: flex;
}
}
.Polaris-PositionedOverlay{
  z-index: 666;
}
.Polaris-Navigation__PrimaryNavigation{
  scroll-behavior: unset !important;
}
nav.Polaris-Navigation {
  background-color: #1b2b34;
  padding-top: 1rem;
  position: relative;
 
  .Polaris-Navigation__ListItem {
    margin: 5px 15px;
    span.Polaris-Navigation__Text {
      color: #fff;
    }
    .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg {
      fill: #fff !important;
    }
    .Polaris-Navigation__Item--selected {
      background: rgba(196, 196, 196, 0.19);
    }
    &:not(:first-child) {
      &:not(:nth-child(2)) {
        .Polaris-Navigation__Item {
          // border-bottom: 1px solid white;
        }
      }
    }
  }
  .navbar-bottom-section {
    flex-basis: auto;
    margin-top: auto;
    > li {
      &:first-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
    > ul {
      &:last-child {
        li.Polaris-Navigation__ListItem {
          &:last-child {
            .Polaris-Navigation__Icon svg {
              fill: red !important;
            }
          }
        }
      }
    }
  }
}
.rating-btn-grp {
  border: 1px solid #f4f6f8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding: 15px 30px;
  margin: auto;
  .rating-button {
    img {
      margin: 0 6px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.welcome-text {
  font-size: 2.5rem;
  font-weight: 600;
  padding: 20px 0;
  @media screen and (max-width: $break-small) {
    text-align: center;
  }
}
.rating-img-block {
  display: flex;
  @media screen and (max-width: $break-small) {
    display: block;
    .img-container {
      text-align: center;
    }
  }
}
.card-subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.progress-with-title {
  padding: 10px 0;
  .Polaris-ProgressBar__Indicator {
    // my
    // position: sticky;
    background-color: #50b83c;
  }
}
.rating-listing {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 5px;
  .star-block {
    display: inline-flex;
    span {
      margin-right: 2px;
      margin-left: 2px;
      svg {
        font-size: 50px;
      }
    }
  }
}
.spacer {
  width: 100%;
  margin: 20px 0;
}
.dashboard-card-title {
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.2rem;
  &.wallet-balance {
    color: #50b83c;
  }
}

.Polaris-Navigation__Section {
  width: 100%;
  flex-basis: auto;
  margin-top: auto;
  .Polaris-Navigation__Item {
    border: none !important;
  }
  .Polaris-Navigation__SectionHeading {
    span {
      color: #fff;
      margin: 5px 15px;
    }
  }
}
.Polaris-Navigation__Badge {
  display: block;
  margin: 0;
  span {
    background: $primaryLight;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: bold;
    border: 1px solid $primaryColor;
  }
}

.star-block {
  svg {
    fill: #f9c132;
  }
  span.disabled {
    margin: 0;
    svg {
      fill: #c5c1c1;
    }
  }
}

.add-fund-btn {
  button {
    background: #58b83c;
    color: #fff;
    font-weight: 600;
    &:hover {
      color: #58b83c;
      background: #fff;
      border-color: #50b83c;
    }
  }
}
.dash-rating-card-container {
  .Polaris-Banner {
    background-color: #fff;
    padding-left: 2rem;
    img {
      max-width: 80px;
    }
    .Polaris-Banner__Ribbon {
      display: none;
    }
  }
}
.align-right {
  text-align: right;
}
.img-txt-inline {
  display: inline-flex;
  text-align: center;
  gap: 5px;
}
.Polaris-Navigation__Section--withSeparator {
  border-top: 0.1rem solid rgb(255 255 255 / 0.25);
}
button.Polaris-Button--primary {
  background: $primaryColor !important;
  border-color: $primaryColor;
  box-shadow: inset 0 1px 0 0 $primaryLight, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  &:active {
    background: $primaryColor !important;
    border-color: $primaryColor;
    box-shadow: inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05),
      0 0 1px $primaryColor;
  }
  &:hover,
  &:focus {
    background: $primaryLight !important;
    border-color: $primaryColor;
  }
  &.Polaris-Button--plain {
    background: none !important;
    border: none;
    box-shadow: none;
    color: $primaryColor;
    padding: 0 1.6rem;
    svg {
      fill: $primaryColor;
    }
  }
}
button.Polaris-Button[aria-label="Disable"] {
  background: #f4f6f8 !important;
  border: 0.1rem solid var(--p-border, #c4cdd5);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
}
.skiptranslate.goog-te-gadget {
  text-align: center;
  margin: 5px 0;
  color: rgb(255 255 255 / 0.7);
  .goog-logo-link {
    color: rgb(255 255 255 / 0.7);
    font-weight: 600;
  }
  select {
    font-size: 1.5rem;
    width: 80%;
    padding: 2px 0;
  }
}
.template-bottom-links {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  &.btn-text--primary {
    .Polaris-Button {
      color: $primaryColor;
    }
  }
  button {
    color: $primaryColor;
    &:hover {
      color: $primaryLight;
      text-decoration: none;
    }
  }
}

.footer {
  position: sticky;
  bottom: 0;
  background: #f4f6f8;
  padding: 8px 20px;
  width: 100%;
  border-top: 0.1rem solid var(--p-border, #c4cdd5);
  font-size: 1.3rem;
  z-index: 99;
  text-align: center;
  right: 0;
  color: #637381;
  @media screen and (max-width: $break-small) {
    text-align: center;
    position: relative;
  }
}
.Polaris-Page {
  margin-bottom: 20px;
  min-height: 85vh;
}
.data-table-selected-label {
  button {
    color: #5c6ac4;
    svg {
      fill: #5c6ac4;
    }
  }
}

.text-primary {
  color: $primaryColor;
}

#hideMe {
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.Polaris-Layout__AnnotatedSection {
  padding: 0 !important;
  border-top: 0 !important;
  outline: none;
}

// .Polaris-Checkbox__Input[aria-checked="true"]{
//     &+.Polaris-Checkbox__Backdrop:after {
//         background-color: $primaryLight !important;
//     }
// }

// .Polaris-Checkbox__Icon svg {
//     fill: #fff !important;
// }
// .Polaris-Checkbox__Input{
//     &:focus,&:active{
//         &+.Polaris-Checkbox__Backdrop{
//             background: $primaryLight !important;
//             box-shadow: 0 0 0 1px $primaryLight, 0 0 0 0 transparent !important;
//             border-color: transparent;
//         }
//     }
// }

.Polaris-Checkbox__Backdrop::before {
  background-color: $primaryLight;
}

tr > th:first-child {
  max-width: 20px;
  padding: 0 0;
}
// 18chnged
tr > th:last-child {
  padding-right: 80px;
  // text-align: center;
}
.Polaris-DataTable__Cell--header {
  font-weight: 500;
  // text-align: center;
  text-align: left;
}

#export_review_buttons {
  button {
    // background-color: #60b8b8;
    background-color: #344a5c;
    color: white;
    svg {
      fill: white;
    }
    &:hover,
    &:active {
      background-color: #344a5c9f;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
#import_review_buttons {
  button {
    // background-color: #5cc99a;
    background-color: #41815e;
    color: white;
    svg {
      fill: white;
    }
    &:hover,
    &:active {
      background-color: #41815eb2;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
.settings_review_btn {
  button {
    background-color: #789191;
    // color:#066969;
    color: white;
    svg {
      fill: white;
      // fill:  #066969;
    }
    &:hover,
    &:active {
      background-color: #3b4646;
      // color: $primaryLight;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
#publish_Clickbtns {
  color: #c14783;
  &:disabled {
    color: #c14783;
  }
}
.hidden_review_btns {
  display: flex;
  flex-direction: column;
  text-align: center;

  button {
    background-color: #c75454;
    width: 100px;
    // color:#066969;
    color: white;
    img {
      fill: white;
    }
    svg {
      fill: white;
      // fill:  #066969;
    }
    &:hover,
    &:active {
      background-color: #e47171;
      // color: $primaryLight;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
#unpublish_review_btns {
  display: flex;
  flex-direction: column;
  text-align: center;
  .Polaris-Button {
    min-height: 3rem !important;
  }
  button {
    background-color: white;
    border: 2px solid #ff7f7f;
    width: 80px;
    // color:#066969;
    color: #ff7f7f;
    img {
      fill: white;
    }
    svg {
      fill: #ff7f7f;
      // fill:  #066969;
    }
    &:hover,
    &:active {
      background-color: #fcd7d7;
      // color: $primaryLight;
      color: #ff7f7f;
      svg {
        fill: #ff7f7f;
      }
    }
  }
}
#pulished_review_btns {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80px;
  .Polaris-Button {
    min-height: 3rem !important;
  }
  button {
    background-color: white;
    border: 2px solid #41815e;
    width: 80px;
    // color:#066969;
    color: #41815e;

    // color:#066969;

    img {
      fill: white;
    }
    svg {
      fill: #41815e;
      // fill:  #066969;
    }
    &:hover,
    &:active {
      background-color: #d2f1e0;
      // color: $primaryLight;
      color: #41815e;

      svg {
        fill: #41815e;
      }
    }
  }
}
#rejectButton_style {
  display: flex;
  flex-direction: column;
  text-align: center;
  .Polaris-Button {
    min-height: 3rem !important;
  }
  .Polaris-Spinner {
    color: #ff7f7f;
  }
  button {
    background-color: white;
    border: 2px solid #ff7f7f;
    width: 75px;
    // color:#066969;
    color: #ff7f7f;

    &:hover,
    &:active {
      background-color: white;
      // color: $primaryLight;
      color: #f32f2f;
      svg {
        fill: #f32f2f;
      }
      // .Polaris-Spinner{}
    }
  }
}
#approveButton_style {
  display: flex;
  flex-direction: column;
  text-align: center;
  .Polaris-Button {
    min-height: 3rem !important;
  }

  button {
    background-color: #41815e;
    color: white;
    width: 75px;

    &:hover,
    &:active {
      background-color: #2a6344;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
// #unpublished {
//   .Polaris-Button__Text {
//     display: flex;
//     align-items: center;
//   }
// }
.reviewContainers {
  min-width: 293px;
  display: flex;
}

td.Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignMiddle {
  position: relative;
  button {
    position: absolute;
    bottom: 10px;
  }
}
body {
  .skiptranslate {
    iframe {
      position: absolute;
      z-index: -1;
    }
  }

  #dashButtons {
    button {
      min-height: auto;
      border-radius: 8px;
      padding: 5px 10px;
      span {
        font-size: 1.2rem;
      }
      &:disabled {
        background: #5d5d5d;
        color: #fff;
      }
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

// .Polaris-Modal-Section{
//         padding: 0;
// }
.stickyProgress {
  // position: sticky;
  position: fixed;
  top: 5.6rem;
  // z-index: 99;
  .Polaris-ProgressBar__Indicator {
    background-color: #009090;
    // my
    // position: fixed;
  }
  .Polaris-ProgressBar {
    background-color: #f4f6f8;
  }
  .Polaris-ProgressBar--sizeSmall {
    height: 0.5rem;
  }
}
// my
.Polaris-Frame__TopBar {
  // margin-top: 20px;
  // z-index: 610;
}
.Polaris-Frame__TopBar {
  // top: 17px;
}
.Polaris-Frame__Navigation {
  // top: 7.6rem;
  z-index: 555;
  
}
// my15

// .Polaris-Stack--alignmentCenter {
//     gap:0;
//     width:205px;
// }
// .Polaris-DataTable__Cell {
//     text-align: center;
// }
// .Polaris-Icon--applyColor{
//     color: $white !important;
// }
// .reviewRequest_reviewRequestAction{
//     margin-left: 35px;

// }
// .Polaris-DataTable__Cell--header {
//     font-weight: 500;
//     text-align: center;
// }

.activeMsgTab button {
  background: rgba(82, 210, 102, 0.1) !important;
  border-radius: 6px !important;
  border: none !important;
  border-left: 5px solid #52d266 !important;
  //   color: #282828 !important;
  span {
    color: #282828 !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
  }
}
.PhoneInputInput {
  border: none !important;
  // &:focus-visible{
  //   border: none;
  // }
}
.PhoneInput--focus {
  border: none !important;
}
#commentsecton {
  .Polaris-Stack > .Polaris-Stack__Item {
    margin-right: 10px;
    margin-left: 0;
  }
}
#reviewrqstsearch {
  width: 50%;
  @media screen and (max-width:$break-small) {
    width: 100%;
  }
}

@media all and (max-width: 960px) {
  html {
    overflow-x: hidden;
  }
}
#whatsappLangid {
  .css-ackcql,
  .css-6j8wv5-Input {
    display: none;
  }
}

.ReactModal__Overlay {
  z-index: 5;
}
// #import_reviews_modals{
// .Polaris-Layout__Section
//   {
//     margin: 0 !important;
//   }
//   .Polaris-Modal-Footer{
//     padding: 1.6rem 3.6rem !important;

//   }
//   .Polaris-Modal-Header{
//     padding: 1.6rem 3.6rem !important;

//   }
// }
#polarisSections_container {
  .Polaris-Layout {
    padding: 0 !important;
    margin: 0 !important;
  }
  //  margin: 0!important;
  .Polaris-Layout__Section {
    margin: 0 !important;
    max-width: 100% !important;
  }
  .Polaris-DropZone {
    background-color: inherit !important;
    z-index: 10;
  }
  .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
    background-color: inherit !important;
  }
}
#reviewStatuscontainer {
  button {
    width: 80px;
    margin-bottom: 5px;
  }
}

#dropZoneParent {
  .Polaris-DropZone-FileUpload--large {
    padding: 5.2rem 3rem 3rem 3rem !important;
  }
}
#indexTableScrollbars {
  .Polaris-Card {
    margin-bottom: 10px;
  }
  .Polaris-IndexTable__ScrollBarContainer {
    display: none;
  }
}
#sorticonStyles {
  display: flex;
  cursor: pointer;

  svg {
    // width: 15px;
    // height: 25px;
    fill: rgb(80, 117, 138, 1);
  }
}
#sorticonStyleicons {
  .Polaris-Icon {
    margin-left: 10px;
  }
}
#productreviewTable {
  tr > th:last-child {
    padding-right: 30px;
    text-align: center;
  }
  tr > td:nth-child(3) {
   width: 200px;
  }
}
#showlayoutsection {
  margin-top: -30px;
  // .Polaris-Layout__Section{
  //   margin-top: 0.5rem !important;
  // }
}
#productratingBtn {
  padding: 5px 0px !important;
}
#Ratingstar {
  padding: 0 !important;
}
#productbadge {
  .Polaris-Badge {
    background-color: #f7cee2;
  }
}

#goog-gt-tt {
  display: none;
}
.goog-te-spinner-pos {
  display: none;
}
#productgroupTable{
  tr > th:first-child {
    max-width: 100px !important;
    padding: 0 4px;
  }
  tr > th:last-child {
    padding-right:10px !important;
    text-align: left;
  }

}
#grouptblbtn{
  button{
    background-color: #344a5c;
    color: #fff;
    font-weight: 600;
    width: 60px;
  }
}
#billingTable{
  .Polaris-Modal-Dialog__Modal {
   
    max-width: 100rem;
 
}
}

// user like icon
@media all and (max-width: 600px) {
  
  .umm-1ikmx2u{
  bottom: calc(0% + 80px) !important;
  }
  .Polaris-Page--fullWidth{
    padding: 0 2rem !important;
  }
}

#dashboard{
  .Polaris-ProgressBar__Indicator{
    border-radius: 6px;
  }
}

#reviewModal div{
  width: 98% !important;
  background-size: contain !important;
  max-height: 500px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: #fff !important;
  max-height: 500px !important;
  @media all and (max-width: 600px) {
  width: 97% !important;
  }
}

.fullPageLoader{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}
.dot-loader{
  display: block;
   width:15px;
    height:15px; 
     border-radius: 50%;
    //  opacity: 0.5;
      background-color: rgb(46, 104, 141);
//       &[data-size="26"]{
// width: 22px;
// height: 22px;
//       }

  will-change: box-shadow;
   animation: pulseWave 1.5s infinite ease;
}

@keyframes pulseWave{
25%{
box-shadow :0 0 0 0 rgba(rgb(163, 188, 204), 0.5);
      transform: scale(0.6);
}  100%{
box-shadow :0 0 0 7px rgba(rgb(163, 188, 204), 0);
transform: scale(1)
} 

} 

// tooltip pricing
.Polaris-Tooltip-TooltipOverlay__Content{
  background-color: #000 !important;
  color: #fff !important;
}

.errorBoundary{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: #000000;
}